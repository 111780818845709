// @ts-nocheck
import axios from "axios";

axios.interceptors.request.use(config => {
    config.timeout = 15000; // Wait for 15 seconds before timing out
    return config;
  });
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        console.log('Request timed out');
      }
      return Promise.reject(error);
    }
  );
  export {axios}